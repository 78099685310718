import { useMutation } from '@tanstack/react-query'

import { IUseMutation } from 'hooks/types'

import { ForgotPassword } from 'validators/forgotPassword'

import { forgotPassword } from './auth.service'

const useForgotPassword = (
  options: IUseMutation<void, unknown, ForgotPassword> = {}
) => {
  return useMutation(forgotPassword, options)
}

export default useForgotPassword
