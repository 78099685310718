import { useRouter } from 'next/router'
import { useQuery } from '@tanstack/react-query'
import { IPossibleLocations } from 'data-transfers/dto'
import { ILocationFilter } from 'data-transfers/requests'

import { IUseQueryHookWithPayload } from 'hooks/types'

import countries from 'data/countries'

import { getLocations } from './map.service'

const useCities = ({
  payload,
  options
}: IUseQueryHookWithPayload<ILocationFilter, IPossibleLocations[]>) => {
  const { locale } = useRouter()

  return useQuery(
    ['cities', payload],
    async () => {
      const data = await getLocations(payload)

      return data.map(({ country: countryCode, city }) => {
        const { translate, ...country } = countries.find(
          (x) => x.code === countryCode
        )!
        return {
          city,
          country: {
            ...country,
            label: translate[locale] ?? country.name
          }
        }
      })
    },
    { refetchOnWindowFocus: false, ...options }
  )
}

export default useCities
