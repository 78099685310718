import { ReactNode, useEffect, useRef } from 'react'
import { Disclosure, Transition } from '@headlessui/react'
import clsx from 'clsx'

import { IconCheck, IconEdit } from 'icons'

import useMediaQuery from 'hooks/useMediaQuery'

import { minWidth } from 'utils'
import { RESPONSIVE } from 'constants/responsive.constant'

import { OrganisationOnboardingStatus } from '../enums'

const AccordionItem = ({
  index,
  title,
  subtitle,
  titleRight,
  description,
  additionalDescription,
  children,
  isOpen,
  isCheck,
  setStep,
  passedSteps
}: {
  index: number
  isOpen: boolean
  isCheck: boolean
  title: string | JSX.Element | undefined
  titleRight?: string | JSX.Element
  subtitle?: string | JSX.Element
  description?: string | JSX.Element
  additionalDescription?: string | JSX.Element
  children: ReactNode
  passedSteps: number[]
  setStep: (step: number) => void
}) => {
  const isDesktop = useMediaQuery(minWidth(RESPONSIVE.lg))
  const ref = useRef<HTMLDivElement>(null)
  const canClick =
    passedSteps.includes(index) &&
    !passedSteps.includes(OrganisationOnboardingStatus.PaymentPending)
  // const canClick = Math.max(...passedSteps) >= index

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        if (ref.current) {
          ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: isDesktop ? 'center' : 'start',
            inline: isDesktop ? 'center' : 'start'
          })
        }
      }, 200)
    }
  }, [isDesktop, isOpen])

  return (
    <Disclosure>
      <div
        className={clsx(
          'relative mb-5 border border-gray-200 bg-white transition-transform',
          !passedSteps.includes(OrganisationOnboardingStatus.PaymentPending) &&
            index === OrganisationOnboardingStatus.PaymentPending &&
            'hidden'
        )}
      >
        <div ref={ref}>
          <Disclosure.Button
            className={clsx(
              'z-[2] flex w-full items-center justify-between border-b-0 bg-white py-5 px-5 transition-all sm:py-10 sm:px-10',
              canClick && 'cursor-pointer hover:bg-gray-50 active:bg-gray-100',
              !canClick && 'cursor-auto'
            )}
            onClick={() => {
              if (canClick) {
                setStep(index)
              }
            }}
          >
            {isCheck && (
              <div className="fade-in absolute top-[-1rem] left-[2.5rem] h-10 w-10 rounded-full border border-gray-900 bg-white text-gray-900">
                <IconCheck />
              </div>
            )}
            <div className="text-left">
              <h5 className="text-2xl font-bold">{title}</h5>
              <h6 className="text-lg text-slate-400">{subtitle}</h6>
            </div>
            {canClick && (
              <div className="ml-5 text-xl font-semibold uppercase">
                {titleRight ?? (!isOpen && <IconEdit className="h-6 w-6" />)}
              </div>
            )}
          </Disclosure.Button>
        </div>
        <Transition
          show={isOpen}
          appear={true}
          className={'h-auto overflow-hidden'}
          enter="transition transition-[max-height] duration-700 ease-in"
          enterFrom="transform max-h-0"
          enterTo="transform max-h-[1000vh]"
          entered="max-h-full  overflow-visible relative z-10"
          leave="transition transition-[max-height] duration-500 ease-out"
          leaveFrom="transform "
          leaveTo="transform max-h-0"
        >
          <Disclosure.Panel className="z-[1] px-10 pb-10">
            <div className="text-base text-slate-400">{description}</div>
            <div className="text-base text-slate-400">
              {additionalDescription}
            </div>
            <div className="relative flex w-full flex-col">{children}</div>
          </Disclosure.Panel>
        </Transition>
      </div>
    </Disclosure>
  )
}

export default AccordionItem
