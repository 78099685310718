import { useQuery } from '@tanstack/react-query'
import { IUserDto } from 'data-transfers/dto'

import { IUseQueryHook } from 'hooks/types'

import { me } from './auth.service'
import { authKeys } from './keys'

const useMe = ({ options }: IUseQueryHook<undefined, IUserDto> = {}) => {
  return useQuery(
    authKeys.me(),
    async () => {
      const data = await me()
      return data
    },
    options
  )
}

export default useMe
