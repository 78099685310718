import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useRef } from 'react'
import { IAddress } from 'data-transfers/dto'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button } from 'components/buttons'
import CountryAutocompleteField from 'components/Fields/CountryAutocomplete'
import { GeoDBCityAutocompleteField } from 'components/Fields/GeoDBCityAutocompleteField'
import Input from 'components/Fields/Input'
import { IForwardRefAutocompleteProps } from 'components/selects/Autocomplete/types'
import { IconClose } from 'icons'

import addressValidator from 'validators/addressValidator'

import { IFormSingleAddressesProps } from './types'

const FormSingleAddresses = ({
  initialValues,
  arrayIndex,
  footer,
  onSubmit
}: IFormSingleAddressesProps) => {
  const { t } = useTranslation(['inputs', 'actions', 'zod'])
  const ref = useRef<IForwardRefAutocompleteProps | null>(null)
  const removeAddress = useCallback(() => {
    const addresses = initialValues?.addresses?.filter(
      (_, index) => index !== arrayIndex
    )
    if (addresses) {
      onSubmit?.({ addresses })
    }
  }, [arrayIndex, initialValues?.addresses, onSubmit])

  const address = useMemo<IAddress>(() => {
    return {
      id: initialValues?.addresses?.[arrayIndex ?? 0]?.id ?? null,
      country: initialValues?.addresses?.[arrayIndex ?? 0]?.country ?? '',
      city: initialValues?.addresses?.[arrayIndex ?? 0]?.city ?? '',
      streetAddress:
        initialValues?.addresses?.[arrayIndex ?? 0]?.streetAddress ?? '',
      houseNumber:
        initialValues?.addresses?.[arrayIndex ?? 0]?.houseNumber ?? '',
      apartmentNumber:
        initialValues?.addresses?.[arrayIndex ?? 0]?.apartmentNumber ?? null,
      zipCode: initialValues?.addresses?.[arrayIndex ?? 0]?.zipCode ?? ''
    }
  }, [arrayIndex, initialValues?.addresses])

  return (
    <Formik
      initialValues={address}
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(addressValidator)}
      onSubmit={(values) => {
        let addresses: IAddress[] | null
        const data = addressValidator.parse(values)
        if (
          !initialValues?.addresses ||
          initialValues.addresses.length === arrayIndex
        ) {
          addresses = [...(initialValues?.addresses ?? []), data]
        } else {
          addresses = initialValues?.addresses?.map((address, index) =>
            index === arrayIndex ? values : address
          )
        }
        if (addresses) {
          onSubmit?.({ addresses })
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mb-4 grid grid-cols-1 items-end  gap-x-5 gap-y-4 md:grid-cols-2 lg:grid-cols-3">
            <CountryAutocompleteField
              name={`country`}
              label={t('country.label', { ns: 'inputs' })}
              placeholder={t('country.placeholder', { ns: 'inputs' })}
              onSelect={() => {
                setFieldValue('city', null)
                ref.current?.clear()
              }}
              isClearable
              required
            />
            <GeoDBCityAutocompleteField
              label={t('city.label', { ns: 'inputs' })}
              required
              ref={ref}
              countryName={values?.country ?? ''}
              name={`city`}
              placeholder={t('city.placeholder', { ns: 'inputs' })}
              onSelect={(value) => {
                if (value?.country) {
                  setFieldValue(`country`, value.country.name)
                }
              }}
              isClearable
            />
            <Input
              name={`streetAddress`}
              label={t('streetAddress.label', { ns: 'inputs' })}
              required
            />
            <Input
              name={`houseNumber`}
              label={t('houseNumber.label', { ns: 'inputs' })}
              required
            />
            <Input
              name={`zipCode`}
              label={t('zipCode.label', { ns: 'inputs' })}
              required
            />
            <div className="mt-5 flex items-end justify-end">
              <Button onClick={removeAddress}>
                <IconClose />
                <span className="ml-2">{t('remove', { ns: 'actions' })}</span>
              </Button>
            </div>
          </div>
          {footer}
        </Form>
      )}
    </Formik>
  )
}

export default FormSingleAddresses
