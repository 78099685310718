import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { SortableItemProps } from '../types'

import Item from './Item'

const SortableItem = (props: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.item.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }
  return (
    <div ref={setNodeRef} style={style}>
      <Item {...props} attributes={attributes} listeners={listeners} />
    </div>
  )
}
export default SortableItem
