import { useMutation } from '@tanstack/react-query'
import { ICandidateSkillDto } from 'data-transfers/dto'
import { IUpdateCandidateSkillsRequest } from 'data-transfers/requests'

import useCVNewStore from 'store/useCVNewStore'
import { IUseMutation } from 'hooks/types'

import { updateCandidateSkills } from './cvCreator.service'

const useUpdateCandidateSkills = ({
  onSuccess,
  ...options
}: IUseMutation<
  ICandidateSkillDto[],
  unknown,
  IUpdateCandidateSkillsRequest
>) => {
  const setTempCV = useCVNewStore((state) => state.setTempCV)
  return useMutation(updateCandidateSkills, {
    onSuccess: (data, variables, context) => {
      setTempCV({ skills: data })
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useUpdateCandidateSkills
