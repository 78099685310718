import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import clsx from 'clsx'

const Dropzone = ({
  width = 250,
  height = 250,
  borderRadius = 125,
  getRootProps,
  getInputProps,
  className,
  children
}: {
  width?: number
  height?: number
  borderRadius?: number
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  className?: string
  children: JSX.Element[] | JSX.Element
}) => {
  return (
    <div
      {...getRootProps()}
      className={clsx(
        `h-[${height}px] w-[${width}px] rounded-[${borderRadius}px]`,
        'fade-in w-full border-2 border-dashed border-black',
        'cursor-pointer opacity-100 transition-all hover:opacity-80 active:opacity-70',
        className
      )}
    >
      <input {...getInputProps()} />
      <div className="p-4 text-center text-gray-700 dark:text-white">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
            />
          </svg>
        </div>
        <div className="mx-auto mb-4 flex w-[60%] flex-col items-center justify-center text-center text-black opacity-60">
          {children}
        </div>
      </div>
    </div>
  )
}

export default Dropzone
