import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

import { ICreateArticleCommentPayload } from './types'

const useFormCreateArticleComment = () => {
  const { t } = useTranslation(['input'])
  const { data, status } = useSession()
  const isAuthenticated = useMemo(() => status === 'authenticated', [])
  const initialValues = useMemo<ICreateArticleCommentPayload>(
    () => ({
      fullName: data?.user.fullName ?? '',
      email: data?.user.email ?? '',
      text: ''
    }),
    [data?.user.email, data?.user.fullName]
  )
  return { t, initialValues, isAuthenticated }
}

export default useFormCreateArticleComment
