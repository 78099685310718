import { useMutation } from '@tanstack/react-query'

import { IUseMutation } from 'hooks/types'

import { resendConfirmEmail } from './auth.service'

const useResendConfirmEmail = (
  options: IUseMutation<void, unknown, string> = {}
) => {
  return useMutation(resendConfirmEmail, options)
}

export default useResendConfirmEmail
