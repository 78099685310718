import { useField } from 'formik'

import { CountryAutocomplete } from 'components/selects'
import { ICountryAutocomplete } from 'components/selects/CountryAutocomplete/types'

const CountryAutocompleteField = ({
  name,
  onSelect,
  onPhoneSelect,
  ...props
}: ICountryAutocomplete & {
  onPhoneSelect?: (value?: string) => void
}) => {
  const [{ value: countryName }, , { setValue }] = useField<string | null>(
    name ?? ''
  )
  return (
    <CountryAutocomplete
      name={name}
      itemToString={(x) => x?.label ?? ''}
      selectedCountryName={countryName}
      onSelect={(value) => {
        setValue(value?.name ?? null)
        onSelect?.(value)
        onPhoneSelect?.(value?.code)
      }}
      {...props}
    />
  )
}

export default CountryAutocompleteField
