import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IDocument } from 'data-transfers/dto'

import { ICVStepLanguagesValidator } from 'validators/new/cv-steps/cVStepLanguages.validator'

import { IUseMutation } from '../types'
import { cvKeys } from '../useCV/keys'

import { setCVLanguagesStep } from './cvCreator.service'

const useSetCVLanguagesStep = ({
  onSuccess,
  ...options
}: IUseMutation<IDocument, unknown, ICVStepLanguagesValidator>) => {
  const queryClient = useQueryClient()

  return useMutation(setCVLanguagesStep, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(cvKeys.all)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useSetCVLanguagesStep
