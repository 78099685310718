import clsx from 'clsx'

import Modal from './Modal'
import { IModalContentProps } from './types'

const ModalContent = ({
  isOpen,
  onClose,
  title,
  subtitle,
  disclaimer,
  children,
  className,
  modalClassName
}: IModalContentProps) => (
  <Modal isOpen={isOpen} onClose={onClose} className={modalClassName}>
    <div
      className={clsx(
        'mb-4 overflow-y-auto overscroll-none px-10 py-8 md:overflow-y-visible md:px-16 md:py-12 lg:px-20 lg:py-16',
        className
      )}
    >
      <div>
        <h5 className="my-2 text-3xl font-bold">{title}</h5>
        <h6 className="text-base text-gray-400">{subtitle}</h6>
        <h6 className="mt-2 text-base text-gray-400">{disclaimer}</h6>
      </div>
      {children}
    </div>
  </Modal>
)

export default ModalContent
