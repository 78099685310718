import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IDocument } from 'data-transfers/dto'

import { ICVStepEducationValidator } from 'validators/new/cv-steps/cVStepEducation.validator'

import { IUseMutation } from '../types'
import { cvKeys } from '../useCV/keys'

import { setCVEducationStep } from './cvCreator.service'

const useSetCVEducationStep = ({
  onSuccess,
  ...options
}: IUseMutation<IDocument, unknown, ICVStepEducationValidator>) => {
  const queryClient = useQueryClient()

  return useMutation(setCVEducationStep, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(cvKeys.all)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useSetCVEducationStep
