import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button } from 'components/buttons'
import Input from 'components/Fields/Input'
import TextArea from 'components/Fields/TextArea'

import createArticleCommentValidator from 'validators/createArticleCommentValidator'

import { IFormCreateArticleCommentProps } from './types'
import useFormCreateArticleComment from './useFormCreateArticleComment'

const FormCreateArticleComment = ({
  className,
  isLoading,
  onSubmit
}: IFormCreateArticleCommentProps) => {
  const { t, initialValues, isAuthenticated } = useFormCreateArticleComment()
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={toFormikValidationSchema(createArticleCommentValidator)}
      onSubmit={onSubmit}
    >
      <Form>
        <div className={className}>
          <div className="flex gap-5">
            <Input
              disabled={isAuthenticated}
              className="w-1/2"
              name="fullName"
              label={t('name2.label', { ns: 'inputs' })}
              placeholder={t('name.placeholder', { ns: 'inputs' })}
              required
            />
            <Input
              disabled={isAuthenticated}
              name="email"
              className="w-1/2"
              label={t('email.label', { ns: 'inputs' })}
              placeholder={t('email.placeholder', { ns: 'inputs' })}
              type="email"
              required
            />
          </div>
          <TextArea
            className="mt-5"
            required
            name="text"
            placeholder={t('comment.placeholder', { ns: 'inputs' })}
            label={t('comment.label', { ns: 'inputs' })}
          ></TextArea>
        </div>
        <span className="select-none text-gray-500">
          * {t('note', { ns: 'community' })}
        </span>
        <div className="mt-5 flex justify-center">
          <Button
            isLoading={isLoading}
            isSpinner
            className="animated-svg"
            type="submit"
          >
            <span>{t('postComment', { ns: 'community' })}</span>
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

export default FormCreateArticleComment
