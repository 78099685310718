import { useMutation } from '@tanstack/react-query'

import { IUseMutation } from 'hooks/types'

import { ResetPassword } from 'validators/resetPassword'

import { resetPassword } from './auth.service'

const useResetPassword = (
  options: IUseMutation<void, unknown, ResetPassword> = {}
) => {
  return useMutation(resetPassword, options)
}

export default useResetPassword
