import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ISetCVPersonalPhotoStepResponse } from 'data-transfers/responses'

import { IUseMutation } from '../types'
import { cvKeys } from '../useCV/keys'

import { setCVPersonalPhotoStep } from './cvCreator.service'

const useSetCVPersonalPhotoStep = ({
  onSuccess,
  ...options
}: IUseMutation<
  ISetCVPersonalPhotoStepResponse,
  unknown,
  File | Blob | null
>) => {
  const queryClient = useQueryClient()

  return useMutation(setCVPersonalPhotoStep, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(cvKeys.all)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useSetCVPersonalPhotoStep
