import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { SortableContainerProps } from '../types'

import SortableItem from './SortableItem'

const SortableContainer = ({
  sortableSkills,
  removeSkill
}: SortableContainerProps) => {
  return (
    <SortableContext
      items={sortableSkills}
      strategy={verticalListSortingStrategy}
    >
      <div
        className={
          'h-[400px] max-w-[700px] flex-1 overflow-x-hidden bg-[#D8D8D826] p-2'
        }
      >
        {sortableSkills.map((skill) => (
          <SortableItem
            key={skill.id}
            item={skill}
            onClick={() => removeSkill(skill.id)}
          />
        ))}
      </div>
    </SortableContext>
  )
}

export default SortableContainer
