import { useMutation } from '@tanstack/react-query'
import { EmailConfirmation, IAuthTokens } from 'data-transfers/dto'

import { IUseMutation } from 'hooks/types'

import { confirmEmail } from './auth.service'

const useConfirmEmail = (
  options: IUseMutation<IAuthTokens, unknown, EmailConfirmation> = {}
) => {
  return useMutation(confirmEmail, options)
}

export default useConfirmEmail
