import { useMutation } from '@tanstack/react-query'
import { IDocument } from 'data-transfers/dto'

import { ICVStepAutoApplyValidator } from 'validators/new/cv-steps/cVStepAutoApply.validator'

import { IUseMutation } from '../types'

import { setCVAutoApplyStep } from './cvCreator.service'

const useSetCVAutoApplyStep = ({
  onSuccess,
  ...options
}: IUseMutation<IDocument, unknown, ICVStepAutoApplyValidator>) => {
  return useMutation(setCVAutoApplyStep, {
    onSuccess: (data, variables, context) => {
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useSetCVAutoApplyStep
