import { ChangeEvent } from 'react'
import clsx from 'clsx'

import { InputStyle } from './Input'

const Range = ({
  name,
  label,
  min,
  max,
  defaultValue,
  onChange,
  errorMsg,
  className
}: {
  name: string
  label: string
  min: number | string
  max: number | string
  defaultValue: number | string
  errorMsg?: string
  className?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}) => (
  <div className={clsx(InputStyle.fieldWrap, className)}>
    <label htmlFor={name} className={InputStyle.label}>
      {label}
    </label>
    <input
      name={name}
      type="range"
      min={min}
      max={max}
      defaultValue={defaultValue}
      onChange={onChange}
      className="range"
    />
    {errorMsg && <div className={InputStyle.error}>{errorMsg}</div>}
  </div>
)

export default Range
