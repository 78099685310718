import { ButtonIcon } from 'components/buttons'
import { IconClose } from 'icons'

import { formatSkillLabel } from 'utils/skill.util'

import { ItemProps } from '../types'

const Item = ({ item, listeners, attributes, onClick }: ItemProps) => (
  <div
    className={
      'mb-2 flex w-fit items-center rounded-full border border-gray-300 bg-white pr-4 '
    }
  >
    <div {...listeners} {...attributes} className={'w-fit px-4 py-2'}>
      {formatSkillLabel(item.value, item.years)}
    </div>
    <ButtonIcon
      rounded
      icon={IconClose}
      className="h-[20px] w-[20px]"
      onClick={() => onClick(item.id)}
    ></ButtonIcon>
  </div>
)

export default Item
