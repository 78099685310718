import { useMutation } from '@tanstack/react-query'
import { ISignUpRequest } from 'data-transfers/requests'

import { IUseMutation } from 'hooks/types'

import { postSignUp } from './auth.service'

const useSignUp = (
  options: IUseMutation<void, unknown, ISignUpRequest> = {}
) => {
  return useMutation(postSignUp, options)
}

export default useSignUp
