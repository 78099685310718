import { useTranslation } from 'next-i18next'
import { useRef } from 'react'
import { Form, Formik } from 'formik'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import CountryAutocompleteField from 'components/Fields/CountryAutocomplete'
import { GeoDBCityAutocompleteField } from 'components/Fields/GeoDBCityAutocompleteField'
import Input from 'components/Fields/Input'
import { IForwardRefAutocompleteProps } from 'components/selects/Autocomplete/types'

import { IAddressFormProps } from './types'

const AddressForm = ({
  initialValue,
  onSubmit,
  validationSchema,
  footer,
  fieldRequired: {
    country = true,
    city = true,
    streetAddress = false,
    houseNumber = false,
    zipCode = false
  }
}: IAddressFormProps) => {
  const { t } = useTranslation(['inputs', 'actions', 'zod'])
  const ref = useRef<IForwardRefAutocompleteProps | null>(null)

  return (
    <Formik
      initialValues={
        initialValue ?? {
          id: null,
          country: '',
          city: '',
          streetAddress: '',
          houseNumber: '',
          zipCode: '',
          apartmentNumber: null
        }
      }
      enableReinitialize={true}
      validationSchema={toFormikValidationSchema(validationSchema)}
      onSubmit={(values) => {
        const data = validationSchema.parse(values)
        onSubmit(data)
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className="mb-4 grid grid-cols-1 gap-x-5  gap-y-4 md:grid-cols-2 lg:grid-cols-3">
            <CountryAutocompleteField
              name={`country`}
              label={t('country.label', { ns: 'inputs' })}
              placeholder={t('country.placeholder', { ns: 'inputs' })}
              required={country}
              onSelect={() => {
                setFieldValue('city', null)
                ref.current?.clear()
              }}
              isClearable
            />
            <GeoDBCityAutocompleteField
              label={t('city.label', { ns: 'inputs' })}
              required={city}
              ref={ref}
              countryName={values?.country ?? ''}
              name={`city`}
              placeholder={t('city.placeholder', { ns: 'inputs' })}
              onSelect={(value) => {
                if (value?.country) {
                  setFieldValue(`country`, value.country.name)
                }
              }}
            />
            <Input
              name={`streetAddress`}
              label={t('streetAddress.label', { ns: 'inputs' })}
              required={streetAddress}
            />
            <Input
              name={`houseNumber`}
              label={t('houseNumber.label', { ns: 'inputs' })}
              required={houseNumber}
            />
            <Input
              name={`zipCode`}
              label={t('zipCode.label', { ns: 'inputs' })}
              required={zipCode}
            />
          </div>
          {footer}
        </Form>
      )}
    </Formik>
  )
}

export default AddressForm
