import { z } from 'zod'

import listItemValidator from 'validators/new/listItem.validator'

const SkillSchema = z.object({
  id: z.string().nullable().optional().nullable(),
  skill: z.string().optional().nullable(),
  skillObj: listItemValidator.optional().nullable(),
  skillId: z.string().nullable(),
  label: z.string().optional(),
  value: z.string().optional()
})

export const candidateSkillSchema = z.object({
  id: z.string().uuid(),
  value: z.string(),
  years: z.number(),
  order: z.number().nullable().optional()
})

export default SkillSchema
