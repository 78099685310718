function dataURItoBlob(dataURI: any) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString
  const dataURISplit = dataURI.split(',')
  if (dataURISplit[0].indexOf('base64') >= 0) byteString = atob(dataURISplit[1])
  else byteString = decodeURI(dataURISplit[1])
  // separate out the mime component
  const mimeString = dataURISplit[0].split(':')[1].split(';')[0]
  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ia], { type: mimeString })
}

export default dataURItoBlob
