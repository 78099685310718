import { useMutation } from '@tanstack/react-query'
import { IAuthTokens } from 'data-transfers/dto'

import { IUseMutation } from 'hooks/types'

import { SignIn } from 'validators/signInValidator'

import { postSignIn } from './auth.service'

const useSignIn = (
  options: IUseMutation<IAuthTokens, unknown, SignIn> = {}
) => {
  return useMutation(postSignIn, options)
}

export default useSignIn
