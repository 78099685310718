/* eslint-disable @typescript-eslint/no-explicit-any */
//TODO convert to scss
//TODO SCROLLBAR
const SelectStyles = (hideIndicator?: boolean, isSimple?: boolean) => ({
  menu: (provided: any) => ({
    ...provided,
    padding: '4px',
    border: 'none',
    outline: 'none'
  }),
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: '9999'
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: '#000',
    backgroundColor: state.isFocused ? '#e8e8e8' : 'transparent',
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    borderRadius: '2px'
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    background: isSimple ? 'transparent' : '#fff',
    minheight: '40px',
    color: '#E5E7EB',
    borderTop: 'none',
    borderleft: 'none',
    borderRight: 'none',
    outline: 'none',
    borderRadius: '0px',
    borderLeftWidth: '0px',
    borderBottomColor: state.isFocused ? '#9ca3af' : '#e5e7eb',
    borderBottomWidth: isSimple ? '0' : '2px',
    boxShadow: 'none',
    '&:hover': {
      color: '#9ca3af'
    }
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: '#E5E7EB',
    border: 'none',
    outline: 'none'
  }),
  placeholder: (provided: any) => ({
    ...provided
  }),
  input: (provided: any) => ({
    ...provided,
    color: '#374151',
    border: 'none',
    outline: 'none'
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    color: '#374151',
    boxShadow: 'none !important'
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    transition: 'transform 300ms',
    transform: state.isFocused ? 'rotate(180deg)' : 'rotate(0deg)'
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: '2px 4px',
    '::-webkit-scrollbar': {
      width: '4px',
      height: '0px'
    },
    '::-webkit-scrollbar-track': {
      background: '#e2000b60',
      borderRadius: '2px'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#e2000b',
      borderRadius: '2px'
    },
    scrollbarColor: '#e2000b #e2000b10',
    scrollbarWidth: 'thin'
    // '::-webkit-scrollbar-thumb:hover': {}
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: '#fff',
    transition: 'all 0.2s ease',
    border: '#000 solid 1px',
    '&:hover': {
      backgroundColor: '#fff3f0',
      color: '#e4002b'
    }
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    display: hideIndicator ? 'none' : 'flex'
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    cursor: 'pointer'
  })
})

export default SelectStyles
