import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IUpdateNameRequest } from 'data-transfers/requests'

import { IUseMutation } from 'hooks/types'

import { updateName } from './auth.service'
import { authKeys } from './keys'

const useUpdateName = ({
  onSuccess,
  ...options
}: IUseMutation<void, unknown, IUpdateNameRequest>) => {
  const queryClient = useQueryClient()

  return useMutation(updateName, {
    onSuccess(data, variables, context) {
      queryClient.invalidateQueries(authKeys.all)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useUpdateName
