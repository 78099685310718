import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IDocument } from 'data-transfers/dto'

import { ICVStepWorkExperienceValidator } from 'validators/new/cv-steps/cVStepWorkExperience.validator'

import { IUseMutation } from '../types'
import { cvKeys } from '../useCV/keys'

import { setCVWorkExperienceStep } from './cvCreator.service'

const useSetCVWorkExperienceStep = ({
  onSuccess,
  ...options
}: IUseMutation<IDocument, unknown, ICVStepWorkExperienceValidator>) => {
  const queryClient = useQueryClient()

  return useMutation(setCVWorkExperienceStep, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(cvKeys.all)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useSetCVWorkExperienceStep
