import { IEducationHistory } from 'data-transfers/dto'

import { IGetEmploymentHistoryDto } from './skill.util'

const orderExperience = (
  arr?: IGetEmploymentHistoryDto[]
): IGetEmploymentHistoryDto[] => {
  if (arr) {
    arr.sort((a, b) => {
      // If experience is still continuing
      if (a.endDate && !b.endDate) {
        return 1
      }
      if (!a.endDate && b.endDate) {
        return -1
      }
      const aStartDate = new Date(a.startDate).getTime()
      const bStartDate = new Date(b.startDate).getTime()
      // if (aStartDate && bStartDate && aStartDate !== bStartDate) {
      //   // Get Newer start first
      //   return bStartDate - aStartDate
      // }
      if (a.endDate && b.endDate) {
        const aEndDate = new Date(a.endDate).getTime()
        const bEndDate = new Date(b.endDate).getTime()
        return bEndDate - aEndDate
      }
      return bStartDate - aStartDate
      // if (a.endDate == b.endDate) {
      //   // Get Newest ongoing experience first
      //   return bStartDate - aStartDate
      // }
      // return 0
    })
  }
  return arr ?? []
}

export const orderEducationExperience = (
  arr?: IEducationHistory[]
): IEducationHistory[] => {
  if (arr) {
    arr.sort((a, b) => {
      // If experience is still continuing
      if (a.endDate && !b.endDate) {
        return 1
      }
      if (!a.endDate && b.endDate) {
        return -1
      }
      const aStartDate = new Date(a.startDate).getTime()
      const bStartDate = new Date(b.startDate).getTime()
      // if (aStartDate && bStartDate && aStartDate !== bStartDate) {
      //   // Get Newer start first
      //   return bStartDate - aStartDate
      // }
      if (a.endDate && b.endDate) {
        const aEndDate = new Date(a.endDate).getTime()
        const bEndDate = new Date(b.endDate).getTime()
        return bEndDate - aEndDate
      }
      return bStartDate - aStartDate
      // if (a.endDate == b.endDate) {
      //   // Get Newest ongoing experience first
      //   return bStartDate - aStartDate
      // }
      // return 0
    })
  }
  return arr ?? []
}

export default orderExperience
