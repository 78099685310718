import { useTranslation } from 'next-i18next'
import { forwardRef, LegacyRef, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'

import Range from 'components/Fields/Range'
import { IconClose } from 'icons'

import { Button } from './buttons'

// eslint-disable-next-line react/display-name
const ImageEditor = forwardRef(
  (
    {
      width = 250,
      height = 250,
      border = 25,
      borderRadius = 150,
      image,
      handleImage,
      editorClassName,
      defaultScale = '50',
      crossOrigin,
      style
    }: {
      width?: number
      height?: number
      border?: number
      borderRadius?: number
      image: File | string | null
      handleImage: (img: File | null) => void
      editorClassName?: string
      defaultScale?: string
      crossOrigin?: '' | 'anonymous' | 'use-credentials'
      style?: object
    },
    ref: LegacyRef<AvatarEditor> | undefined
  ) => {
    const { t } = useTranslation(['actions', 'inputs'])
    const [scale, setScale] = useState<string>(defaultScale)
    return (
      <div className="fade-in flex flex-wrap justify-center gap-y-5 gap-x-10 sm:flex-nowrap">
        <div
          className={
            editorClassName ||
            `w-fit overflow-hidden rounded-[${borderRadius}px]`
          }
        >
          <AvatarEditor
            ref={ref}
            width={width}
            height={height}
            border={border}
            image={image ?? ''}
            crossOrigin={crossOrigin}
            borderRadius={borderRadius}
            scale={1 + parseInt(scale) / 200}
            style={style}
          />
        </div>
        <div>
          <Range
            name="scale"
            label={t('scale.label', { ns: 'inputs' })}
            min={-100}
            max={100}
            defaultValue={scale}
            onChange={(e) => setScale(e.target.value)}
            className="mb-5"
          />
          <Button
            type="button"
            onClick={() => {
              handleImage(null)
            }}
            className="mx-auto sm:mx-0"
          >
            <IconClose />
            <span className="ml-2">{t('remove', { ns: 'actions' })}</span>
          </Button>
        </div>
      </div>
    )
  }
)

export default ImageEditor
