import { useMutation, useQueryClient } from '@tanstack/react-query'
import { IDocument } from 'data-transfers/dto'

import { ICVStepPreferencesValidator } from 'validators/new/cv-steps/cVStepPreferences.validator'

import { IUseMutation } from '../types'
import { cvKeys } from '../useCV/keys'

import { setCVPreferencesStep } from './cvCreator.service'

const useSetCVPreferencesStep = ({
  onSuccess,
  ...options
}: IUseMutation<IDocument, unknown, ICVStepPreferencesValidator>) => {
  const queryClient = useQueryClient()

  return useMutation(setCVPreferencesStep, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(cvKeys.all)
      onSuccess?.(data, variables, context)
    },
    ...options
  })
}

export default useSetCVPreferencesStep
