import { closestCenter, DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import { formatSkillLabel } from 'utils/skill.util'

import { Autocomplete } from '../index'

import SortableContainer from './components/SortableContainer'
import { TransferSkillListProps } from './types'
import useTransferSkillList from './useTransferSkillList'

const TransferSkillList = ({ skills, name }: TransferSkillListProps) => {
  const {
    sensors,
    items,
    dndValues,
    autocompleteRef,
    t,
    handleDragEnd,
    removeSkill,
    addSkill
  } = useTransferSkillList({ skills, name })

  return (
    <div className={'mt-5 mb-8 flex h-[420px] flex-col gap-5'}>
      <Autocomplete
        ref={autocompleteRef}
        name={name}
        items={items}
        itemToString={(item) =>
          item ? formatSkillLabel(item.value, item.years) : ''
        }
        placeholder={t('skillsMultiple.placeholder', { ns: 'inputs' })}
        isClearable
        clearAfterSelect
        onSelect={(selectedItem) => addSkill(selectedItem)}
        className={'mb-2'}
      />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContainer
          sortableSkills={dndValues}
          removeSkill={removeSkill}
        />
      </DndContext>
    </div>
  )
}

export default TransferSkillList
