import { forwardRef, useCallback, useMemo, useState } from 'react'
import { IPossibleLocations } from 'data-transfers/dto'
import { useField } from 'formik'
import { debounce } from 'throttle-debounce'

import { Autocomplete } from 'components/selects'
import {
  IAutocompleteProps,
  IForwardRefAutocompleteProps
} from 'components/selects/Autocomplete/types'

import useCities from 'hooks/useMap/useCities'

const GeoDBCityAutocompleteField = forwardRef<
  IForwardRefAutocompleteProps,
  Omit<IAutocompleteProps<IPossibleLocations>, 'items'> & {
    countryName?: string
  }
>(function GeoDBCityAutocompleteField(
  { name, countryName, onSelect, ...props },
  ref
) {
  const [{ value }, , { setValue }] = useField<string | null>(name ?? '')
  const [namePrefix, setNamePrefix] = useState<string>(value ?? '')

  const [isOpen, setIsOpen] = useState(false)
  const { data, isFetching } = useCities({
    payload: {
      country: !countryName && !namePrefix ? 'Lithuania' : countryName,
      cityPrefix: namePrefix
    },
    options: {
      enabled: isOpen
    }
  })

  const selectedValue = useMemo<IPossibleLocations | null>(() => {
    if (value) {
      return { country: null, city: value }
    }
    return null
  }, [value])

  const changeHandler = useCallback((value: string | null) => {
    setNamePrefix(value ?? '')
  }, [])
  const debouncedChangeHandler = useMemo(
    () => debounce(1000, changeHandler),
    [changeHandler]
  )

  return (
    <Autocomplete
      ref={ref}
      isLoading={isFetching}
      items={data ?? []}
      name={name}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onChange={debouncedChangeHandler}
      itemToString={(x) => x?.city ?? ''}
      {...props}
      onSelect={(value) => {
        setNamePrefix(value?.city ?? '')
        setValue(value?.city ?? null)
        onSelect?.(value)
      }}
      selectedItem={selectedValue}
    />
  )
})

export { GeoDBCityAutocompleteField }
