import { useTranslation } from 'next-i18next'
import { Form, Formik } from 'formik'
import { candidateSkillSchema } from 'schemas/skill.schema'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

import { Button } from 'components/buttons'
import TransferSkillList from 'components/selects/TransferSkillList/TransferSkillList'
import { IconArrow } from 'icons'

import useCVNewStore from 'store/useCVNewStore'
import { useUpdateCandidateSkills } from 'hooks/useCVCreator'

const SkillsUpdateForm = () => {
  const { t } = useTranslation(['actions'])
  const closeModals = useCVNewStore((state) => state.closeModals)
  const tempCV = useCVNewStore((state) => state.tempCV)
  const skills = tempCV?.skills ?? []
  const { mutate } = useUpdateCandidateSkills({})
  return (
    <Formik
      initialValues={{ skills: skills.filter((x) => x.order) }}
      onSubmit={(values) => {
        mutate({ ids: values.skills.map((x) => x.id) })
        closeModals()
      }}
      validationSchema={toFormikValidationSchema(
        z.object({
          skills: candidateSkillSchema
            .array()
            .min(1, 'custom.select1Skill')
            .max(10, 'custom.select10Skill')
        })
      )}
    >
      <Form>
        <TransferSkillList skills={skills} name={'skills'} />
        <div className="flex flex-wrap justify-between gap-4">
          <Button onClick={closeModals}>
            <span className="mr-2 text-sm">
              {t('cancel', { ns: 'actions' })}
            </span>
          </Button>
          <Button className="animated-svg" type="submit">
            <span className="mr-2 text-sm">{t('save', { ns: 'actions' })}</span>
            <IconArrow />
          </Button>
        </div>
      </Form>
    </Formik>
  )
}

export default SkillsUpdateForm
