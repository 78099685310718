import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useRef } from 'react'
import {
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { DragEndEvent } from '@dnd-kit/core/dist/types'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { ICandidateSkillDto } from 'data-transfers/dto'
import { useFormikContext } from 'formik'

import { IForwardRefAutocompleteProps } from '../Autocomplete/types'

import { TransferSkillListProps } from './types'

const useTransferSkillList = ({ skills, name }: TransferSkillListProps) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<{
    skills: ICandidateSkillDto[]
  }>()
  const { t } = useTranslation(['inputs'])
  const autocompleteRef = useRef<IForwardRefAutocompleteProps>(null)

  const items = useMemo(
    () => skills.filter((x) => !values.skills.some((y) => y.id == x.id)),
    [skills, values.skills]
  )
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = values.skills.findIndex((x) => x.id == active.id)
      const newIndex = values.skills.findIndex((x) => x.id == over.id)

      const sortableRes = arrayMove(values.skills, oldIndex, newIndex)

      setFieldValue(name, sortableRes)
    }
  }

  const removeSkill = useCallback(
    (id: string) => {
      const sortableRes = values.skills.filter((skill) => skill.id !== id)

      setFieldValue(name, sortableRes)
    },
    [name, setFieldValue, values.skills]
  )

  const addSkill = useCallback(
    (selectedItem: ICandidateSkillDto | null) => {
      if (!selectedItem) {
        return
      }
      const sortableRes = [...values.skills, selectedItem]

      setFieldTouched(name, true, true)
      setFieldValue(name, sortableRes, true)
      autocompleteRef.current?.clear()
    },
    [name, setFieldTouched, setFieldValue, values.skills]
  )

  return {
    sensors,
    items,
    t,
    dndValues: values.skills,
    autocompleteRef,
    handleDragEnd,
    removeSkill,
    addSkill
  }
}

export default useTransferSkillList
