const listSortCallback = <T>(
  options: { label: string | number; value: T; order?: number }[]
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (inputValue: string, callback: (opt: any) => void) => {
    callback(() =>
      !inputValue || inputValue === ''
        ? options
        : options
            .filter((item) =>
              item.label
                .toString()
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            )
            .sort((_, b) =>
              b.label.toString().toLowerCase() === inputValue.toLowerCase()
                ? 1
                : 0
            )
    )
  }
}

export default listSortCallback
