import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'
import { SingleValue } from 'react-select'
import Select from 'react-select/async'
import clsx from 'clsx'
import { ErrorMessage, useField } from 'formik'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import { InputStyle } from 'components/Fields/Input'

import { useDocument } from 'hooks/useDocument'
import { ListType, useList } from 'hooks/useList'

import { ILocale } from 'types/app'
import listSortCallback from 'utils/listSortCallback'
import SelectStyles from 'utils/SelectStyle'
import { getZodMessage } from 'utils/validation.util'

export interface Option {
  readonly value: string
  readonly label: string
  readonly color?: string
  readonly isFixed?: boolean
  readonly isDisabled?: boolean
  readonly order?: number
  readonly languageCode?: ILocale
}

export type SelectProps = {
  name: string
  label: string
  isClearable?: boolean
  required?: boolean
  helperText?: string
  className?: string
  placeholder?: string
  type: ListType
  menuPosition?: 'absolute' | 'fixed'
  disabled?: boolean
  isCandidate?: boolean
  onChange?: (value: Option | null) => void
}

export const ALL_FIELD_OF_STUDY_ID = '00000000-0000-0000-0000-000000000000'
export const ALL_DEGREE_ID = '00000000-0000-0000-0000-000000000000'

const SelectListItem = ({
  name,
  label,
  className,
  required,
  helperText,
  type,
  isClearable,
  placeholder,
  onChange: onChangeFn,
  isCandidate = false,
  menuPosition = 'absolute'
}: SelectProps) => {
  const { t } = useTranslation(['zod', 'ad_form'])
  const document = useDocument()
  z.setErrorMap(makeZodI18nMap(t))
  const { locale: languageCode } = useRouter()
  const [field, , { setValue, setTouched }] = useField(name)
  const { data, isLoading } = useList({ languageCode, type })
  const SelectStyle = useMemo(() => SelectStyles(), [])

  const options = useMemo(() => {
    const list = data?.map((item) => ({
      ...item,
      label: item.value ?? '',
      value: item.id ?? '',
      order: item.order ?? undefined
    }))

    if (type === 'EducationDegree' && !isCandidate) {
      list?.unshift({
        id: ALL_DEGREE_ID,
        label: t('education.optionAllDegree', { ns: 'ad_form' }),
        value: ALL_DEGREE_ID,
        order: 0,
        languageCode
      })
    }

    if (type === 'FieldOfStudy' && !isCandidate) {
      list
        ?.sort((a, b) => (a.label === b.label ? 0 : a.label > b.label ? 1 : -1))
        .unshift({
          id: ALL_FIELD_OF_STUDY_ID,
          label: t('education.optionAll', { ns: 'ad_form' }),
          value: ALL_FIELD_OF_STUDY_ID,
          order: 0,
          languageCode
        })
    }

    return list ?? []
  }, [data, isCandidate, languageCode, t, type])

  const onChange = (item: SingleValue<Option>) => {
    if (item) {
      setValue(item.value)
    } else {
      setValue(null)
    }
  }

  return (
    <div className={clsx(InputStyle.fieldWrap, className)}>
      <label className={InputStyle.label} htmlFor={name}>
        {label}
        {required && <span className={InputStyle.required}>*</span>}
      </label>
      <Select
        name={field.name}
        styles={SelectStyle}
        value={options.find((item) => item.value === field.value)}
        loadOptions={listSortCallback(options)}
        defaultOptions={options}
        // TODO REMOVE THIS
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onChange={onChangeFn || onChange}
        classNamePrefix="react-select"
        isLoading={isLoading}
        options={options}
        isClearable={isClearable}
        menuPortalTarget={document?.body}
        menuPosition={menuPosition}
        menuPlacement="auto"
        menuShouldScrollIntoView={false}
        onBlur={() => setTouched(true)}
        placeholder={placeholder}
      />

      <div className={InputStyle.info}>
        <div className={InputStyle.helper}>{helperText}</div>
        <ErrorMessage
          name={name}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div
                className={clsx(
                  InputStyle.error,
                  'fade-in text-medium text-left text-error-400'
                )}
              >
                {getZodMessage(msg, t(msg, { ns: 'zod' }))}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default SelectListItem
