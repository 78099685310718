import { ICandidateSkillDto, IDocument } from 'data-transfers/dto'
import { IUpdateCandidateSkillsRequest } from 'data-transfers/requests'
import { ISetCVPersonalPhotoStepResponse } from 'data-transfers/responses'
import { ICVStepAutoApplyValidator } from 'validators/new/cv-steps/cVStepAutoApply.validator'
import { ICVStepEducationValidator } from 'validators/new/cv-steps/cVStepEducation.validator'
import { ICVStepLanguagesValidator } from 'validators/new/cv-steps/cVStepLanguages.validator'
import { ICVStepPersonalInformationValidator } from 'validators/new/cv-steps/cVStepPersonalInformation.validator'
import { ICVStepPreferencesValidator } from 'validators/new/cv-steps/cVStepPreferences.validator'
import { ICVStepWorkExperienceValidator } from 'validators/new/cv-steps/cVStepWorkExperience.validator'
import { authClient } from 'utils'
import { CandidateIndustries } from 'components/modals/CandidateNotificationsModal/modals/FillUserIndustriesNotification/types'

export interface ISetCVPersonalInformationStep
  extends ICVStepPersonalInformationValidator {
  nativeLanguage?: string | null
}

export const setCVEducationStep = async (
  education: ICVStepEducationValidator
) => {
  const { data } = await authClient.patch<IDocument>(
    '/documents/education',
    education
  )
  return data
}
export const setCVLanguagesStep = async (step: ICVStepLanguagesValidator) => {
  const { data } = await authClient.patch<IDocument>(
    '/documents/languages',
    step
  )
  return data
}
export const setCVPersonalInformationStep = async (
  step: ISetCVPersonalInformationStep
) => {
  const { data } = await authClient.patch<IDocument>(
    '/documents/personal-information',
    step
  )
  return data
}
export const setCVPersonalPhotoStep = async (file: File | Blob | null) => {
  const formData = new FormData()
  if (file) {
    formData.set('Photo', file)
  }
  const { data } = await authClient.patch<ISetCVPersonalPhotoStepResponse>(
    '/documents/photo',
    formData,
    {
      headers: {
        Accept: 'application/text',
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  return data
}
export const setCVPreferencesStep = async (
  step: ICVStepPreferencesValidator
) => {
  const { data } = await authClient.patch<IDocument>(
    '/documents/preferences',
    step
  )
  return data
}

export const setCVWorkExperienceStep = async (
  step: ICVStepWorkExperienceValidator
) => {
  const { data } = await authClient.patch<IDocument>(
    '/documents/work-experience',
    step
  )
  return data
}

export const setCVAutoApplyStep = async (step: ICVStepAutoApplyValidator) => {
  const { data } = await authClient.patch<IDocument>(
    '/documents/auto-apply',
    step
  )
  return data
}

export const updateCandidateSkills = async (
  payload: IUpdateCandidateSkillsRequest
) => {
  const result = await authClient.patch<ICandidateSkillDto[]>(
    `/documents/skills`,
    payload
  )
  return result.data
}
export const sendUserIndustries = async (payload: CandidateIndustries) => {
  const candidateId = payload.candidateId
  const response = await authClient.put<CandidateIndustries>(
    `documents/${candidateId}/industries`,
    {
      industries: payload.industries
    }
  )
  return response.data
}
